import './contracts.module.css';
import BaseLayout from "../../components/base-layout/base-layout";

/* eslint-disable-next-line */
export interface ContractsProps {}

export function Contracts(props: ContractsProps) {
  return (
    <BaseLayout>
      Hello
    </BaseLayout>
  );
}

export default Contracts;
