import './base-layout.module.css';

/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useState} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {BellIcon, MenuIcon, XIcon} from '@heroicons/react/outline'
import {ReactComponent as Logo} from "../../assets/p_logo.svg";
import {ReactComponent as Loading} from "../../assets/loading.svg";
import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import axios from "axios";

/* eslint-disable-next-line */
export interface BaseLayoutProps {
}

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
  {name: 'Contract', href: '#', current: true},
  {name: 'Workflows', href: '#', current: false},
  {name: 'Team', href: '#', current: false},
  {name: 'Dashboard', href: '#', current: false},
  {name: 'Documents', href: '#', current: false},
]
const userNavigation = [
  {name: 'Your Profile', href: '#'},
  {name: 'Settings', href: '#'},
  {name: 'Sign out', href: '#'},
]


const docData = {
  provisions: [
    {
      section: '1.1.1',
      content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
      tags: ['obligation', 'deliverable']
    },
    {
      section: '1.1.2',
      content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
      tags: ['obligation']
    }
  ]
}

function MyDropzone() {
  const [file, setFile] = useState<File | string>('')
  const [numPages, setNumPages] = useState(0);
  const [nav, setNav] = useState('contract');
  const [docData, setDocData] = useState<any | null>(null);
  const [analysing, setAnalysing] = useState<boolean>(false)

  const onDocumentLoadSuccess = ({numPages}: any) => {
    setNumPages(numPages);
  }

  const analyseFile = (file1: File) => {
    setAnalysing(true)
    const fileData = new FormData();
    fileData.append('files', file1);
    axios
      .post('/api/', fileData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setDocData(response.data)
        setAnalysing(false)
      })
      .catch((error) => {
        console.log(error)
        setAnalysing(false)
      });
  }

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0])
    analyseFile(acceptedFiles[0])
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  if (analysing) {
    return <div className={'w-full text-center'}>
      <Loading/>
      <div>Crunching Data...</div>
    </div>
  }
  return (
    file ?
      <>
        <div className={'w-64 rounded bg-gray-200 absolute left-32 top-32 z-50 h-64'}>
          <div className={'m-auto py-2 px-4 text-sm cursor-pointer hover:bg-gray-400'}
               onClick={() => setNav('contract')}>Original document
          </div>
          <div className={'m-auto py-2 px-4 text-sm cursor-pointer hover:bg-gray-400'}
               onClick={() => setNav('table')}>Intelligent document
          </div>
        </div>
        {nav === 'contract' &&
        <div className={'w-screen overflow-y-scroll z-0 relative'} style={{height: 'calc(100vh - 64px)'}}>
          <Document className={'w-full bg-gray-300 m-auto flex flex-col items-center'} file={file}
                    onLoadSuccess={onDocumentLoadSuccess}>
            {[...Array(numPages).keys()].map(page => <Page width={600} pageNumber={page + 1}/>)}
          </Document>
        </div>}
        {nav === 'table' && <div className={'w-1/2 m-auto mt-8'}>
          <div className={'flex mb-4 justify-between'}>
            <div className={'w-28 flex-shrink-0'}>Section</div>
            <div className={'flex-grow'}>Content</div>
            <div className={'w-24 flex-shrink-0 flex-grow-0 text-center'}>Tags</div>
            <div className={'w-24 flex-shrink-0 flex-grow-0 text-center'}>Status</div>
          </div>
          {docData && docData.map((item: any) =>
            <div className={'flex mb-4 cursor-pointer hover:bg-gray-200 rounded-lg p-2'}>
              <div className={'w-28 flex-shrink-0'}>{item.section}</div>
              <div className={'flex-grow'}>{item.content}</div>
              <div
                className={'w-24 flex-shrink-0 flex-grow-0 text-center self-center'}>{item.tags && item.tags.map((tag: any) =>
                <div
                  className={`rounded-lg p-1 ${tag == 'obligation' ? 'bg-blue-200' : 'bg-green-200'} m-2 text-xs`}>{tag}
                </div>)}
              </div>
              <div className={'w-24 flex-shrink-0 flex-grow-0 text-center self-center'}>
                <div className={`rounded-lg p-1 bg-red-200 m-2 text-xs`}>incomplete</div>
              </div>
            </div>)}
        </div>}
      </>
      :
      <div {...getRootProps()} className={'rounded p-8 bg-gray-200 w-96 m-auto mt-16'}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the PDF here ...</p> :
            <p>Drag 'n' drop a PDF here or click to select a PDF</p>
        }
      </div>
  )
}

export function BaseLayout(props: BaseLayoutProps) {
  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-gray-800">
          {({open}) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <Logo width={'45'} className={'text-white'}/>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={`${
                              item.current
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white'}
                              px-3 py-2 rounded-md text-sm font-medium`}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <button
                        type="button"
                        className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true"/>
                      </button>

                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button
                            className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">Open user menu</span>
                            <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt=""/>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({active}) => (
                                  <a
                                    href={item.href}
                                    className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button
                      className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true"/>
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={`${
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'}
                        'block px-3 py-2 rounded-md text-base font-medium'
                      `}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="pt-4 pb-3 border-t border-gray-700">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt=""/>
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">{user.name}</div>
                      <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true"/>
                    </button>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/*<header className="bg-white shadow">*/}
        {/*  <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">*/}
        {/*    <h1 className="text-3xl font-bold text-gray-900">Dashboard</h1>*/}
        {/*  </div>*/}
        {/*</header>*/}
        <main>
          <MyDropzone/>
        </main>
      </div>
    </>
  );
}

export default BaseLayout;
