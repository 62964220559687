import styles from './app.module.css';

import { ReactComponent as Logo } from './logo.svg';
import star from './star.svg';
import {Route, Routes} from "react-router-dom";
import Contracts from "../pages/contracts/contracts";
import { BrowserRouter } from "react-router-dom";

export function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Contracts />} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
